@tailwind base;
@tailwind components;
@tailwind utilities;

.paginationButton {
    background-color: #1F2937;
    border: none;
    margin-right: 4px;
    color: #FFCC40;
}

.paginationButtonActive {
    background-color: #1F2937;
    border: none;
    margin-right: 4px;
    border-bottom-color: #FFCC40;
    border-bottom-style: solid;
    border-bottom-width: 4px;
    pointer-events: none;
}

.paginationButtonActive:hover {
    /* No changes on hover */
}

.paginationInput {
    outline: none !important;
}