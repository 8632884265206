@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins";
}
.leaflet-container {
  width: 100%;
  max-width: 1200px;
  margin: 36px auto;
  height: 70vh;
}
.mainHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  height: 80px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 7px #ccc !important;
}

.navbar {
  display: flex;
  list-style: none;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  padding: 12px;
  width: auto !important;
}
.Navbar {
  display: flex;
  list-style: none;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  padding: 12px;
}
span.item {
  padding: 12px;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 16px;
}
.submenu {
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 20px;
  height: 60px;
  align-items: center;
  background-color: #fff;
  box-shadow: "0px 0px 7px #ccc";
}
@media only screen and (max-width: 1024px) {
  .navbar {
    display: none;
  }
  body .mobileMenu {
    flex-flow: nowrap !important;
  }
}

.footer-container {
  background: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
  color: #fff !important;
  padding: 12px 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 140px;
}
@media only screen and (max-width: 600px) {
  .mainHeader {
    height: 50px;}
}